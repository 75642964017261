<template>
  <div class="page-app-customer-event-log">
    <div class="wrap">
      Username:
      <input class="input" type="text" v-model="login.username" />
      <br />Password:
      <input class="in" type="password" v-model="login.password" />
      <br />
      <button class="btn btn-primary" @click="submitLogin">submit</button>
      <br />
      <div v-if="login_error_response">Login ERROR: {{ login_error_response }}</div>

      <div>JWT Expired? (boolean): {{ auth_jwt_is_expired ? 'true' : 'false' }}</div>
      <div v-if="auth_jwt_expiry">JWT Expiry (timestamp): {{ auth_jwt_expiry }}</div>
      <div v-if="auth_id_email">Login Identity Email: {{ auth_id_email }}</div>
      <div v-if="auth_current_spid">Current SPID: {{ auth_current_spid }}</div>
      <div v-if="auth_current_sp_roles.length">Current SP Roles: {{ auth_current_sp_roles }}</div>
      <div v-if="auth_current_customeruuid">Current Customer UUID: {{ auth_current_customeruuid }}</div>

      <div
        v-if="auth_current_cust_roles.length"
      >Current Customer Roles: {{ auth_current_cust_roles }}</div>
    </div>
  </div>
</template>

<script>
import * as uuid from "uuid/v4";
import { mapGetters } from "vuex";

import appCustomerEventLog from "@/components/app-customer-event-log";
import appComments from "@/components/app-comments";

export default {
  components: {
    appCustomerEventLog,
    appComments
  },
  data() {
    return {
      login: {
        username: "abc",
        password: ""
      },
      login_error_response: ""
    };
  },
  methods: {
    submitLogin() {
      console.log("submitLogin");
      this.$store
        .dispatch("AuthCheck", {
          IdentityProvider: "LOCAL",
          AuthID: this.login.username,
          AuthCode: this.login.password
        })
        .then(response => {
          this.login_error_response = null;
          console.log("login ok");
        })
        .catch(error => {
          this.login_error_response = error;
        });
    }
  },
  computed: {
    ...mapGetters({
      auth_jwt_expiry: "jwt_expiry",
      auth_jwt_is_expired: "jwt_is_expired",
      auth_id_email: "id_email",
      auth_current_sp_roles: "current_sp_roles",
      auth_current_cust_roles: "current_cust_roles",
      auth_current_customeruuid: "current_customeruuid",
      auth_current_spid: "current_spid"
    })
  }
};
</script>

<style lang="scss">
.page-app-customer-event-log {
  padding: 80px 0;

  .wrap {
    width: 100%;
    max-width: 1195px;
    margin: 0 auto 80px;
    padding: 0 30px;

    .app-sidebar {
      width: 100%;
      max-width: 585px;
      margin: 0 auto;
      padding: 0 32px;

      .shadow {
        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-primary);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>